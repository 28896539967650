import React from "react"
import { Helmet } from "react-helmet"
import TopBar from "../components/topBar"

import "../styles/App.scss"
import "../styles/Lead.scss"
import "../styles/Other.scss"

import bg from "../images/Bg9.png"
import rightBg from "../images/Icons_Abilities3c.png"
import BotBar from "../components/BotBar"

export default function privacypolicy() {
  const seo = {
    title: "Legends of Idleon",
    description: "",
    image: "https://www.LegendsOfIdleon.com/share.png",
    url: "https://www.LegendsOfIdleon.com/",
  }

  return (
    <div
      className="AppContainer"
      style={{ backgroundImage: `url(${rightBg})` }}
    >
      <Helmet>
        <title>{seo.title}</title>
        <meta name="robots" content="noindex"></meta>

        <meta name="description" content={seo.description} />
        <meta name="image" content={seo.image} />
        <meta property="og:url" content={seo.url} />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={seo.title} />
        <meta property="og:description" content={seo.description} />
        <meta property="og:image" content={seo.image} />
        <meta property="og:image:type" content="image/png" />
        <meta property="og:image:width" content="1024" />
        <meta property="og:image:height" content="500" />
        <meta property="og:image:alt" content="Play Legends of Idleon" />
        <meta name="twitter:card" content="summary_large_image" />
        <meta name="twitter:title" content={seo.title} />
        <meta name="twitter:description" content={seo.description} />
        <meta name="twitter:image" content={seo.image} />

        <link rel="icon" type="image/png" href="/favicon64.png" />
        <link rel="apple-touch-icon" href="/logo192_a.png" />
        <link
          href="https://fonts.googleapis.com/css2?family=Nunito:wght@600&family=Oxygen&display=swap"
          rel="stylesheet"
        ></link>

        {/*TODO <script type="application/ld+json">
          {`
              {
                "@context": "https://schema.org",
                "@type": "Organization",
                "url": "https://www.LegendsOfIdleon.com",
                "logo": "https://www.LegendsOfIdleon.com/logo192_a.png",
                "name": "Legends of Idleon",
              }
            `}
        </script> */}
      </Helmet>
      <div className="LeftGradient" />
      <div className="RightGradient" />
      <div className="App">
        <div
          className="Content2"
          style={{ backgroundImage: `url(${bg})`, backgroundColor: "#f3d4c7" }}
        >
          <TopBar />

          <div className="RealContent" style={{ backgroundColor: "#d0d0d0" }}>
            <header className="" style={{ padding: "0 20px" }}>
              <h1>Unknown Error Occured</h1>
              <p>Make sure you have the Idleon Android App Installed</p>

              <a className="PlayBrowserButton" href="/">
                <div className="PlayBrowserText">Back to Home Page</div>
                <div className="PlayBrowserText2"></div>
              </a>
            </header>
          </div>

          <BotBar />
        </div>
      </div>
    </div>
  )
}
